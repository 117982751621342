import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';

import arrowBlue from 'assets/images/arrow-blue.png';
import calendarPng from 'assets/images/calendar.png';
import ModalEndSubmit from 'components/Organisms/Modals/ModalEndSubmit/ModalEndSubmit';
import UploadDocs from 'components/Atoms/UploadDocs/UploadDocs';
import { LoanContext } from 'scenes/Private/Dashboard/Dashboard';
import { File, Loan, TaskState } from 'gql/graphql';
import { useCreateResponseTask, useDeleteFileResponse } from 'services/task/mutations';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import ModalFinish from '../ModalFinish';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';

interface Props {
	task: any;
	setModalTaskVisible: any;
	redirectTrackRecord: any;
	generateCircles: any;
	countProperties?: number;
	response: any;
	closeTask: any;
}

const Step1 = ({
	task,
	setModalTaskVisible,
	redirectTrackRecord,
	generateCircles,
	countProperties,
	response,
	closeTask
}: Props) => {
	const loan: Loan | any = useContext(LoanContext);

	const { execute: _deleteFile } = useDeleteFileResponse();
	const { fetching, execute: createResponseTask } = useCreateResponseTask();
	const { reexecute: getTaskResponses } =
		useGetTaskResponsesByTask({
			variables: { findResponsesByTask: { taskId: task._id } }
		});

	const [modalFinish, setModalFinish] = useState<boolean>(false);
	const [isModalConfirm, setIsModalConfirm] = useState(false);
	const [listFileUpload, setListFileUpload] = useState([]);
	const [taskCompleted, setTaskCompleted] = useState<boolean>();

	useEffect(() => {
		setTaskCompleted(response?.state === TaskState.Approved)
	}, [response])

	const submit = async () => {
		let payload: any = {
			key: 'property-huds',
			label: 'Property HUDs',
			step: 1,
			metadata: {},
			state: TaskState.Review,
			task: task?._id,
			files: listFileUpload.filter((item: any) => !!item.size)
		};

		if (response) payload._id = response._id;

		await createResponseTask(payload);
		setIsModalConfirm(false);
		getTaskResponses({ requestPolicy: 'network-only' });

		if ((response?.files?.length === 1 && listFileUpload.length === 1) || listFileUpload.length === 2)
			setModalFinish(true);

		setListFileUpload([]);

	};

	const mixedFiles = () => {
		const mixed = [...(response?.files || []), ...listFileUpload];

		const include: any = {};

		return mixed.filter((item) => {
			if (!include[item.name]) {
				include[item.name] = true;
				return true;
			}
			return false;
		});
	};

	const onDeleteFile = (file: File) => {
		_deleteFile({ fileKey: file.key, taskResponseId: response._id });
	}

	return (
		<div className="children InvestmentExperienceVerification">
			<h2 className="h2 mt-5">
				Upload the HUDs for at least {loan.investmentExperience.properties}{' '}
				properties
			</h2>
			<p className="h4 h4-w4 mt-5">
				Don’t remember which properties we’re referring to?{' '}
				<span className="blue-bold" onClick={redirectTrackRecord}>
					View the list of properties you used to verify your experience
					<img src={arrowBlue} className="arrow" alt="arrow" />
				</span>
			</p>

			{taskCompleted &&
				<SectionSuccessTask
					title="You've completed this task."
					button={{ title: "Back to dashboard", onClick: closeTask }}
				/>
			}

			{!taskCompleted &&
				<SectionErrorTask taskId={task._id} />
			}

			<div
				className={`card__generic card__generic-${response?.state} mt-50`}
				style={{ position: 'relative' }}
			>
				<StateTag state={response?.state} isLabelLocked />

				<div className="flex-btw mt-10">
					<p className="h4">Property HUDs</p>
					<span>Past properties: {countProperties?.toString()}</span>
				</div>
				{countProperties && (
					<UploadDocs
						listFileUpload={mixedFiles() || listFileUpload}
						setListFileUpload={setListFileUpload}
						numberOfFiles={countProperties}
						onDeleteFile={onDeleteFile}
						circles={{
							label: 'Submitted HUDs',
							states: generateCircles()
						}}
						button={
							<ModalEndSubmit
								visible={isModalConfirm}
								setVisible={setIsModalConfirm}
								buttonAction={{
									label: !response ? 'Add at least one HUD to submit' : 'Submit other HUDs',
									disabled:
										listFileUpload.length === 0 ||
										response?.files?.filter((item: any) => item.state === 'review')?.length === 2
								}}
								modal={{
									title: 'Ready to submit?',
									description: (
										<h4 className="h4 h4-w4">
											{`After submitting, you'll have to contact us to make any changes.`}
										</h4>
									)
								}}
								className="modal-end-submit--white"
								icon={calendarPng}
								buttonSubmit={
									<Button
										className="button button-modal-ok"
										loading={fetching}
										onClick={submit}
									>
										Yes, this is correct
									</Button>
								}
								buttonCancel={(actionCancel: any) => (
									<Button
										className="button button-modal-cancel"
										onClick={actionCancel}
									>
										I want to make changes
									</Button>
								)}
							/>
						}
					/>
				)}
			</div>

			{/*<AddAnotherDoc taskId={task._id} taskState={task.state}/>*/}

			<CollapseIssues taskId={task._id} rejectionCode='no_action_required' />

			{modalFinish &&
				<ModalFinish
					visible={modalFinish}
					setVisible={setModalFinish}
					closeTask={() => setModalTaskVisible(false)}
				/>
			}
		</div>
	);
};
export default Step1;
