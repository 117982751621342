import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Entity,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';
import { LoanContext } from '../../Dashboard';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const TitleCompany = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const loan: any = useContext(LoanContext);
  
  const { data: taskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });
  
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse | any>();
  const [entity, setEntity] = useState<Entity | any>();
  const [isReview, setIsReview] = useState(false);

  useEffect(() => {
    if (openContext === 'title_company') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    const step1 = taskResponses?.find((item) => item.step === 1);
    if (step1) setResponseStep1(step1);

    setIsReview(step1?.state === TaskState.Review);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const redirectLoanDoc = () => {
    const docTitle = loanDocs?.find(item => item.key === 'title');
    window.open(docTitle?.file?.url, "_blank");
  }

  const { taskName, phase } = findTaskRules(ModuleKeys.TITLE_COMPANY);
  
  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          responses={taskResponses}
          renderButtonTrigger={(openModal: any) => (
            <>
              {(!responseStep1 || responseStep1?.key !== 'not-title') &&
                <Button
                  className={`button-open-modal button-open-modal-${task.state}`}
                  onClick={() => openModal(true)}
                >
                  {task.state === TaskState.Review || task.state === TaskState.RequestError ?
                    'Complete task' :
                    task.state === TaskState.Draft ?
                      'Begin Task' : 'View Task'
                  }
                </Button>
              }
              {task?.state === TaskState.Approved && responseStep1?.key === 'not-title' &&
                <Button
                  className={`button-open-modal button-open-modal-${task.state}`}
                  onClick={redirectLoanDoc}
                >
                  View doc
                </Button>
              }
            </>
          )}
          header={{
            title: <span>{phase} | {taskName.toUpperCase()}</span>,
            actionLeft: (
              isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
            ),
            actionRight: (
              <Button
                className="button button-transparent"
                onClick={() => onHelpDocs()}
              >
                <QuestionCircleOutlined />
                Help docs
              </Button>
            )
          }}
          animationDirection="onRight"
          stepsTotal={1}
          currentStep={step}
          setStep={setStep}
          footer={
            <div className="text-footer mt-20">
              <p className="placeholder">
                My title company is{' '}
                {responseStep1?.metadata?.titleCompany &&
                  <span className="bold-label">
                    {responseStep1?.metadata?.titleCompany}
                  </span>
                }
              </p>
            </div>
          }
        >
          <section className="children AchPaymentForm">
            <Step1
              setStep={setStep}
              closeTask={() => setModalTaskVisible(false)}
            />
          </section>
        </ModalTask>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default TitleCompany;
