import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import {
  Entity,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { PhasesLabel } from '../../LoanPhases.constanst';
import { TaskOpenModalContext } from '../../../Private';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const NewTask = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse>();

  useEffect(() => {
    if (openContext === task._id) {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (isModalTaskVisible)
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [isModalTaskVisible])

  useEffect(() => {
    const step1: any = taskResponses?.find((item) => item.step === 1);
    setResponseStep1(step1);
    setIsReview(step1?.state === TaskState.Review);
  }, [taskResponses]);

  return (
    <TaskContext.Provider value={task}>
      <ModalTask
        visible={isModalTaskVisible}
        handlerSetVisibile={setModalTaskVisible}
        isConfirmModalClose={task.state !== TaskState.Approved && task.state !== TaskState.Review}
        responses={taskResponses}
        renderButtonTrigger={(openModal: any) => (
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
          >
            {task.state === TaskState.Review || task.state === TaskState.RequestError ?
              'Complete task' :
              task.state === TaskState.Draft ?
                'Begin Task' : 'View Task'
            }
          </Button>
        )}
        header={{
          title: <span>{task.phase && PhasesLabel[task.phase]} | {task.name.toUpperCase()}</span>,
          actionLeft: (
            isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
          )
        }}
        animationDirection="onRight"
        stepsTotal={1}
        currentStep={step}
        setStep={setStep}
        footer={null}
      >
        <section className="children AchPaymentForm">
          <Step1 closeTask={() => setModalTaskVisible(false)}/>
        </section>
      </ModalTask>
    </TaskContext.Provider>
  );
};
export default NewTask;