import React, { useContext, useEffect, useState } from 'react';
import { Loan, Task, TaskState } from 'gql/graphql';
import { PlusCircleOutlined } from '@ant-design/icons';

import CardOwnership from '../CardOwnership/CardOwnership';
import { TaskContext } from '../EntityGuarantorCreation';
import SectionErrorTask from 'scenes/Private/components/SectionErrorTask/SectionErrorTask';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import CollapseIssues from 'scenes/Private/components/CollapseIssues/CollapseIssues';
import ButtonSubmit from '../../../../../../components/Atoms/Buttons/ButtonSubmit/ButtonSubmit';
import { useCreateResponseTask } from '../../../../../../services/task/mutations';
import DropdownBlue from '../../../../../../components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import { useGetTaskResponsesByTask } from '../../../../../../services/task/querys';
import CardOwnershipEdit from '../Step2/CardOwnershipEdit/CardOwnershipEdit';
import { capitalizeNames } from '../../../../../../@common/utils/formatters/formatter';
import { useCreateOwnership } from '../../../../../../services/ownership/mutations';
import useGuarantorUtil from '../useGuarantorUtil';
import { LoanContext } from '../../../Dashboard';
import { useGetOwnershipsByCreator } from '../../../../../../services/ownership/querys';

interface Step3Props {
  setStep: Function;
  closeTask: Function;
  responsesStep2: any[];
  responseStep1: any;
  entity: any;
}
const Step3 = ({ setStep, responseStep1, responsesStep2, closeTask, entity }: Step3Props) => {
  const task: Task | any = useContext(TaskContext);
  const loan: Loan | any = useContext(LoanContext);

  const { data: responseCreateOwner, execute: fetchCreateOwnership } = useCreateOwnership();
  const { data: responseCreateTask, fetching, execute: createResponseTask } = useCreateResponseTask();
  const { data: ownershipsCreated, reexecute: getOwnershipsByCreator } = useGetOwnershipsByCreator({});
  const { reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [newOwner, setNewOwner] = useState<any>();
  const [ownerships, setOwnerships] = useState<any>([]);
  const [taskCompleted, setTaskCompleted] = useState<boolean>();
  const [createPending, setCreatePending] = useState<any>();
  const [ownerExpand, setOwnerExpand] = useState<any>();

  const { getGuarantorsNameElements, getTotal, getSomeoneNews, selectOwnersCache } = useGuarantorUtil({ ownerships, ownershipsUserCreator: ownershipsCreated });

  useEffect(() => {
    if (ownerExpand) {
      const ownerUpdate = ownerships.find((item: any) => item.ownerId === ownerExpand.ownerId && item.id === ownerExpand.id);
      setOwnerExpand(ownerUpdate);
    }
  }, [ownerships])

  useEffect(() => {
    if (responseCreateOwner) {
      const ownerId = responseCreateOwner._id;
      const payload = createPending;

      payload.metadata.ownerId = ownerId;
      createResponseTask(payload);
      setCreatePending(undefined);
    }
  }, [responseCreateOwner])

  useEffect(() => {
    if (responseCreateTask) {
      setNewOwner(null);
      getTaskResponses({ variables: { taskId: task._id }, requestPolicy: 'network-only' });
    }
  }, [responseCreateTask]);

  useEffect(() => {
    const _ownerships = [];

    if (responsesStep2?.length > 0) {
      for (const taskResponse of responsesStep2) {
        const { _id, ...modelOwner } = ownershipsCreated?.find(item =>
          item._id === taskResponse.metadata.ownerId
        ) || {};
        _ownerships.push({
          ...modelOwner,
          key: taskResponse._id,
          state: taskResponse.state,
          files: taskResponse.files,
          ...taskResponse.metadata,
          taskResponse
        });
      }
      setTaskCompleted(responsesStep2.every(item => item.state === TaskState.Approved));
      setOwnerships(_ownerships);
    }
  }, [responsesStep2]);

  const disabledSubmitReview = () => {
    return !ownerships.find((item: any) => item.state === TaskState.Draft);
  }

  const submitForReview = async () => {
    for (const ownership of ownerships) {
      if (ownership.taskResponse.state === TaskState.Draft) {
        const {
          files: ignoreFile,
          task,
          __typename,
          createdAt,
          ...taskResponse
        } = ownership.taskResponse;

        await createResponseTask({
          ...taskResponse,
          task: task._id,
          state: TaskState.Review,
          metadata: taskResponse.metadata,
        });
      }
    }
  }

  const createOwnership = async (values: any, state?: TaskState) => {
    const { files, ...ownership } = values;

    ownership.name = capitalizeNames(ownership.name);
    ownership.lastname = capitalizeNames(ownership.lastname);

    const ownerId =
      responseStep1.metadata.ownerId &&
        responseStep1.metadata.ownerId !== 'someone_new'
        ? responseStep1.metadata.ownerId
        : undefined;

    const metadata = responsesStep2.length === 0
      ? { _id: ownerId, ...ownership }
      : ownership;

    let payload: any = {
      task: task._id,
      key: `ownership-${values.name}`,
      label: `Ownership ${values.name}`,
      step: 2,
      metadata,
      state: state ? state : ownership.ownership >= 20 ? TaskState.Incomplete : TaskState.Draft
    };

    //Es un archivo recien subido
    const isFile = files?.some((item: any) => item.size);

    if (isFile) payload.files = files;
    else payload.filesExist = files;

    if (ownership.ownership >= 20) {
      await fetchCreateOwnership({
        ...metadata,
        entity: entity._id,
        loan: loan._id,
        state: TaskState.Incomplete,
        stakes: {
          [entity._id]: ownership.ownership
        }
      });
      setCreatePending(payload);
    } else {
      //Cuando se crea desde el dropdown, se crea sin porcentaje
      const modelOwner = ownershipsCreated?.find(item => item._id === ownership._id)

      if (modelOwner) {
        payload.state = TaskState.Incomplete;

        await fetchCreateOwnership({
          _id: modelOwner._id,
          entity: entity._id,
          loan: loan._id,
          stakes: {
            ...modelOwner.stakes,
            [entity._id]: ownership.ownership
          }
        });
        setCreatePending(payload);
      } else
        createResponseTask(payload);
    }
  };

  const itemCardOwner = (ownership: any, index: number) => {
    const isExpand = (ownership?._id && ownerExpand?._id === ownership?._id) ||
      (ownership?.ownerId && ownerExpand?.ownerId === ownership?.ownerId) ?
      ownerExpand : undefined;

    const isDisabled = ownership.state !== TaskState.RequestError &&
      ownership.state !== TaskState.Draft &&
      ownership.state !== TaskState.Incomplete

    return (
      <CardOwnership
        key={index}
        index={index}
        ownership={ownership}
        disabledForm={isDisabled}
        hiddenSubmitChanges={isDisabled}
        showTagState
        isModalSubmit
        ownerships={ownerships}
        closeTask={closeTask}
        stepVerify={true}
        isActionRemoved={
          ownership.state !== TaskState.Review &&
          ownership.state !== TaskState.RequestError &&
          ownership.state !== TaskState.Approved
        }
        isOwnerModelEdit
        entity={entity}
        onDeleteOwner={() => getOwnershipsByCreator({ requestPolicy: 'network-only' })}
        isExpand={isExpand}
        onExpand={setOwnerExpand}
      />
    );
  }

  return (
    <section className="step-3">
      <div className="section-1">
        {ownerships && (
          <h3 className="h2">
            <>
              <span>We’ve added </span>
              <label>{getGuarantorsNameElements()}</label>
              {ownerships.length === 1 && <span>{" as a guarantor on the loan. Let's get their profile filled out"}</span>}
              {ownerships.length > 1 && <span>{" as guarantors on the loan. Let’s get their profiles filled out"}</span>}
            </>
          </h3>
        )}

        {taskCompleted &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: closeTask }}
          />
        }

        {!taskCompleted &&
          <SectionErrorTask taskId={task._id} />
        }

        <div className="content-cards mt-30">
          {ownerExpand && itemCardOwner(ownerExpand, 99)}

          {ownerships.filter((item: any) => {
            return (item?._id && ownerExpand?._id !== item?._id) ||
              (item?.ownerId && ownerExpand?.ownerId !== item?.ownerId)
          }).map((ownership: any, index: number) =>
            itemCardOwner(ownership, index))
          }

          {newOwner && (
            <CardOwnershipEdit
              onFinish={createOwnership}
              ownership={newOwner}
              fetching={fetching}
              totalPercentage={getTotal()}
              disabledNames={ownerships.length === 0 && newOwner.name}
              onSaveDraft={(v) => createOwnership(v)}
              handlerClose={() => setNewOwner(null)}
              tagStateGuarantor={"incomplete"}
            />
          )}
        </div>

        <div className="content-owner-new mt-10">
          <div className="text-add">
            <PlusCircleOutlined />
            <span>Add</span>
            <DropdownBlue
              label="someone new"
              items={getSomeoneNews(!!newOwner)}
              onClickSelect={(key: string) => selectOwnersCache(key, setNewOwner, createOwnership)}
            />
            <span>as an owner</span>
          </div>
          <div className={`total ${getTotal() !== 100 && 'total-error'}`}>
            <span>Total</span>
            <b>{getTotal()}%</b>
          </div>
        </div>

        <div className='mt-15'>
          <ButtonSubmit
            loading={false}
            modalConfirm={{ show: true }}
            onClick={submitForReview}
            disabled={disabledSubmitReview()}
          >
            {disabledSubmitReview() ?
              'Add 100% ownership to submit entity for review' :
              'Submit entity and profiles for revew'
            }
          </ButtonSubmit>
        </div>


        <CollapseIssues taskId={task._id} rejectionCode='no_action_required' />
      </div>
    </section>
  );
};
export default Step3;
