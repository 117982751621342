import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/cort.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Task, TaskState } from 'gql/graphql';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';
import { LoanContext } from '../../Dashboard';
import { useGetLoanDocByLoan } from '../../../../../services/loan/querys';

export const PayAppraisalTaskContext = createContext<Task | undefined>(undefined);

interface PayAppraisalProps {
  task: Task;
  userEmail: string;
}
const PayAppraisal = ({ task, userEmail }: PayAppraisalProps) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const loan: any = useContext(LoanContext);

  const { data: loanDocs } = useGetLoanDocByLoan({ variables: { loanId: loan._id } });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);

  const [step, setStep] = useState(1);

  useEffect(() => {
    if (openContext === 'pay_appraisal') {
      setModalTaskVisible(true);
      setHandler();
    } else if (!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const redirectLoanDoc = () => {
    const docTitle = loanDocs?.find(item => item.key === 'appraisal');
    window.open(docTitle?.file?.url, "_blank");
  }

  const { taskName, phase } = findTaskRules(ModuleKeys.PAY_APPRAISAL);

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      isConfirmModalClose={task.state !== TaskState.Approved && task.state !== TaskState.Review}
      renderButtonTrigger={(openModal: any) => (
        task.state !== TaskState.Approved ?
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
            hidden={task.state === TaskState.Review}
          >
            {task.state === TaskState.RequestError ? 'Complete task' :
              task.state === TaskState.Draft ? 'Begin Task' : 'View doc'
            }
          </Button>
          :
          <Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={redirectLoanDoc}
          >
            View doc
          </Button>
      )}
      header={{
        title: <span>{phase} | {taskName.toUpperCase()}</span>,
        actionLeft: (
          <img
            src={iconCortPng}
            alt="icon"
            className="paperPng"
          />
        ),
        actionRight: (
          <Button
            className="button button-transparent"
            onClick={() => onHelpDocs()}
          >
            <QuestionCircleOutlined />
            help docs
          </Button>
        )
      }}
      animationDirection="onRight"
      stepsTotal={1}
      currentStep={step}
      setStep={setStep}
      footer={
        <div className="text-footer mt-20">
          <p className="placeholder">
            My appraisal <span className="bold-label">is not paid</span>
          </p>
        </div>
      }
    >
      <PayAppraisalTaskContext.Provider value={task}>
        <section className="children PayAppraisal">
          <Step1
            task={task}
            closeTask={() => setModalTaskVisible(false)}
            userEmail={userEmail}
          />
        </section>
      </PayAppraisalTaskContext.Provider>
    </ModalTask>
  );
};
export default PayAppraisal;
