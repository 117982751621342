import { Button } from "antd";
import React from "react";

const Header = () => {
  return (
    <div className="Header">
      <Button className="button button-secondary">Sign in</Button>
    </div>
  );
};
export default Header;
