import React, { useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/cort.png';
import QuestionBasic from 'components/Organisms/QuestionBasic/QuestionBasic';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import CardSomeoneElse from 'components/Atoms/Cards/CardSomeoneElse/CardSomeoneElse';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { useGetTaskResponsesByTask } from 'services/task/querys';
import { useCreateResponseTask } from 'services/task/mutations';
import SectionSuccessTask from 'scenes/Private/components/SectionSuccessTask/SectionSuccessTask';
import ModalFinish from './ModalFinish';
import { useGetUserLoggin } from '../../../../../services/user/querys';

interface PropertyAccessDetailsProps {
  task: Task;
}

const PropertyAccessDetails = ({ task }: PropertyAccessDetailsProps) => {

  const { data: user } = useGetUserLoggin();
  const { execute: createResponseTask } = useCreateResponseTask();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });
  
  const [isReview, setIsReview] = useState(false);
  const [response, setResponse] = useState<TaskResponse>();
  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isModalFinish, setModalFinish] = useState(false);

  useEffect(() => {
    if(task?.state !== TaskState.Approved && taskResponses?.length === 0) 
      setModalTaskVisible(true);
  }, [task, taskResponses])

  useEffect(() => {
    const _response: any = taskResponses?.find((item) => item.step === 1);
    setIsReview(_response?.state === TaskState.Review);
    setResponse(_response);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const onSubmit = async (option: any, metadata?: any) => {
    if (!response) {
      await createResponseTask({
        key: option.id === 1 ? 'me' : 'someone-else',
        label: 'Who will be coordinating access for appraisers and other vendors?',
        step: 1,
        metadata: metadata || {},
        state: TaskState.Approved,
        task: task?._id
      });
      getTaskResponses({ requestPolicy: 'network-only' });
      setModalFinish(true);
    }
  };

  const { taskName, phase } = findTaskRules(ModuleKeys.PROPERTY_ACCESS);

  return (
    <ModalTask
      visible={isModalTaskVisible}
      handlerSetVisibile={setModalTaskVisible}
      responses={taskResponses}
      renderButtonTrigger={(openModal: any) =>
        task.state === TaskState.Approved ? null :
          (<Button
            className={`button-open-modal button-open-modal-${task.state}`}
            onClick={() => openModal(true)}
          >
            {task.state === TaskState.Review || task.state === TaskState.RequestError ?
              'Complete task' :
              task.state === TaskState.Draft ?
                'Begin Task' : 'View Task'
            }
          </Button>)
      }
      header={{
        title: <span>{phase} | {taskName.toUpperCase()}</span>,
        actionLeft: (
          isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
        ),
        actionRight: (
          <Button
            className="button button-transparent"
            onClick={() => onHelpDocs()}
          >
            <QuestionCircleOutlined />
            Help docs
          </Button>
        )
      }}
      animationDirection="onRight"
      stepsTotal={1}
      currentStep={1}
      setStep={() => console.log('action')}
      footer={
        <p className="body-regular mt-20">
          The main contact for the property is
        </p>
      }
    >
      <div className="children">
        <h2 className="h3">
          Welcome, {user?.name}! We need a few things to get you preapproved for your
          loan.
        </h2>

        {response &&
          <SectionSuccessTask
            title="You've completed this task."
            button={{ title: "Back to dashboard", onClick: () => setModalTaskVisible(false) }}
          />
        }

        <div className="mt-50">
          <QuestionBasic
            question={
              <h3 className="h2">
                Who will be coordinating access for appraisers and other
                vendors?
              </h3>
            }
            type="dropdown"
            options={[
              {
                id: 1,
                title: 'Me',
                onClick: onSubmit
              },
              {
                id: 2,
                title: 'Someone else',
                dropdown: (metadata: any) => (
                  <CardSomeoneElse handlerSubmit={onSubmit} response={response} />
                ),
                styleHover: 'option-hover-blue',
                styleActive: 'option-active-blue', 
                onClick: () => { }
              }
            ]}
          />
        </div>
        <ModalFinish
          visible={isModalFinish}
          setVisible={setModalFinish}
          closeTask={() => setModalTaskVisible(false)}
        />
      </div>
    </ModalTask>
  );
};
export default PropertyAccessDetails;
