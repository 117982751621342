import React, { useEffect, useRef, useState } from 'react';
import uploadSvg from 'assets/images/upload.svg';
import uploadCircleSvg from 'assets/images/upload-svg.svg';
import InputUploadedFile from 'components/Atoms/Inputs/InputUploadedFile/InputUploadedFile';
import { File } from '@common/utils/interfaces/file.interface';

interface Props {
  button?: React.ReactElement | null;
  numberOfFiles: number;
  listFileUpload: File[] | [];
  setListFileUpload: Function;
  style?: any;
  circles?: { label: string; states: string[] };
  loading?: boolean;
  disabled?: boolean;
  onDeleteFile?: Function;
}

const UploadDocs = ({
  button,
  numberOfFiles,
  listFileUpload,
  setListFileUpload,
  style,
  circles,
  loading,
  disabled,
  onDeleteFile
}: Props) => {

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isDragging, setIsDragging] = useState(false);

  const handleDragOver = (e: any) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => setIsDragging(false);

  const handleDrop = (e: any) => {
    e.preventDefault();
    setIsDragging(false);
    const droppedFiles = Array.from(e.dataTransfer.files);
    if (droppedFiles.length <= numberOfFiles) {
      for (const file of droppedFiles) {
        const reader: any = new FileReader();
        reader.onload = () => {
          const isFile: any = file
          isFile.url = reader.result;
          setListFileUpload((prevList: any) => [...prevList, isFile]);
        };
        if (file)
          reader.readAsDataURL(file);
      }
    } else {
      //alert('Only one file can be uploaded.');
      if (fileInputRef.current) fileInputRef.current.value = '';
    }

  };

  const handleChangeFile = (event: any) => {
    if (event.target.files[0]) {
      const file = event.target.files[0];
      const reader: any = new FileReader();
      reader.onload = () => {
        const isFile = event.target.files[0]
        isFile.url = reader.result
        setListFileUpload([...listFileUpload, isFile]);
      };
      if (file)
        reader.readAsDataURL(file);
    }
  };

  const deleteFile = (file: any): void => {
    if(onDeleteFile) onDeleteFile(file);

    setIsDragging(false);
    const arrayFiles = listFileUpload;
    const deleteFile = arrayFiles.findIndex((item) => item.name === file.name);
    if (deleteFile >= 0) {
      arrayFiles.splice(deleteFile, 1);
    }
    setListFileUpload([...arrayFiles]);
    if (fileInputRef?.current)
      fileInputRef.current.value = '';
  };
  
  return (
    <div className="UploadDocs" style={style}>
      {circles && (
        <div className="flex-btw">
          <h4 className="body-card">{circles.label}</h4>
          <div className="circles">
            {circles.states.map((state, index) => (
              <div key={index} className={`circle circle-${state}`} />
            ))}
          </div>
        </div>
      )}

      <div className="contenedor-flex">
        <div className={`felx-btw UploadDocs--container`}>
          {listFileUpload.length < numberOfFiles && (
            <div
              className={`input-content ${isDragging ? 'input-content-drag' : ''}`}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
              onDrop={handleDrop}
            >
              {loading &&
                <div className="spinner-container">
                  <div className="spinner">
                    <div className="spinner-ring"></div>
                    <div className="spinner-percentage">50%</div>
                  </div>
                </div>
              }
              {!loading &&
                <>
                  <input
                    type="file"
                    disabled={disabled}
                    ref={fileInputRef}
                    onChange={handleChangeFile}
                    accept="image/png,image/jpg,image/jpeg,application/pdf"
                  />

                  <div className="empty-input">
                    <img src={uploadSvg} alt="upload" className="upload" />
                    <p className="body-small body-small-w6">
                      Drag and drop files, or <span className="blue">Browse</span>
                    </p>
                    <p className="small-detail">Supports png or jpeg files</p>
                  </div>
                </>
              }
            </div>
          )}
          {listFileUpload.length >= 1 && (
            <div className="cont-files">
              {listFileUpload?.map((file: any, index) => (
                <InputUploadedFile
                  key={index}
                  file={file}
                  deleteFile={file.state !== 'approved' && file.state !== 'review' ? deleteFile : undefined}
                  isActionSeeFile={true}
                  icon={uploadCircleSvg}
                  state={file.state}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {button}
    </div>
  );
};
export default UploadDocs;
