import { Task, TaskState } from "gql/graphql";
import { ModuleKeys } from "services/task/modules-keys.constanst";
import { Phases, PhasesLabel } from "./LoanPhases.constanst";
import { LoanPurposes, LoanTypes } from "services/loan/type.constansts";

interface IRulesTaskCard {
  key: string;
  modalKey?: string;
  name: string;
  filters?: string[];
  phase: string[];
  phaseProgress: string;
  state?: string;
  loanType?: string[];
  loanPurposes?: string[];
  card?: any;
  data?: Task;
  progressButtons?: any;
}

export const RulesTasksCards: IRulesTaskCard[] = [
  {
    key: ModuleKeys.PROPERTY_ACCESS,
    name: 'Property access details',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
    state: TaskState.Approved,
  },
  {
    key: ModuleKeys.ENTITY_GUARANTORS,
    name: 'Entity Ownership And Guarantors',
    modalKey: 'entity_guarantor',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
  },
  {
    key: ModuleKeys.MINIMUM_LIQUIDITY,
    name: 'Proof of Liquidity',
    modalKey: 'minimum_liquidity',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
  },
  {
    key: ModuleKeys.TRACK_RECORD,
    name: 'Past Investment Experience',
    modalKey: 'track_record',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.DUE_DILIGENCE,
    loanType: [LoanTypes.FF, LoanTypes.BIDGE],
    progressButtons: {
      DRAFT: { label: 'Submit HUDs', state: 'not submitted' },
      INCOMPLETE: { label: 'Submit HUDs', state: 'not submitted' },
      REVIEW: { label: 'View', state: 'in review' },
      APPROVED: { label: 'View', state: 'verified' },
      REQUEST_ERROR: { label: 'View', state: 'error' }
    }
  },
  {
    key: ModuleKeys.ENTITY_DOCS,
    name: 'Entity Docs',
    modalKey: 'entity_docs',
    phase: [Phases.DUE_DILIGENCE],
    phaseProgress: Phases.DUE_DILIGENCE,
    progressButtons: {
      DRAFT: { label: 'Submit docs', state: 'not submitted' },
      INCOMPLETE: { label: 'Submit docs', state: 'not submitted' },
      REVIEW: { label: 'View', state: 'in review' },
      APPROVED: { label: 'View', state: 'verified' },
      REQUEST_ERROR: { label: 'View', state: 'error' }
    }
  },
  {
    key: ModuleKeys.INVESTMENT_EXPERIENCE,
    name: 'Investment Experience Verification',
    modalKey: 'investment_experience',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.DUE_DILIGENCE,
    loanType: [LoanTypes.FF, LoanTypes.BIDGE],
  },
  {
    key: ModuleKeys.CONSTRUCTION,
    name: 'Construction Budget/SOW',
    modalKey: 'construction',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
    loanType: [LoanTypes.FF],
  },
  {
    key: ModuleKeys.TITLE_COMPANY,
    name: 'Title Company',
    modalKey: 'title_company',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
    progressButtons: {
      DRAFT: { label: 'Submit', state: 'not submitted' },
      INCOMPLETE: { label: 'Submit', state: 'not submitted' },
      REVIEW: { label: 'View', state: 'in review' },
      APPROVED: { label: 'View', state: 'verified' },
      REQUEST_ERROR: { label: 'View', state: 'error' }
    }
  },
  {
    key: ModuleKeys.INSURANCE,
    name: 'Insurance',
    modalKey: 'insurance',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.DUE_DILIGENCE,
    progressButtons: {
      DRAFT: { label: 'Request quote', state: 'not submitted' },
      INCOMPLETE: { label: 'Request quote', state: 'not submitted' },
      REVIEW: { label: 'No action', state: 'pending' },
      APPROVED: { label: 'View', state: 'verified' },
      REQUEST_ERROR: { label: 'View', state: 'error' }
    }
  },
  {
    key: ModuleKeys.PURCHASE,
    name: 'Purchase agreement',
    modalKey: 'purchase',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.APPROVAL,
    loanPurposes: [LoanPurposes.PURCHASE],
  },
  {
    key: ModuleKeys.PAYOFF,
    name: 'Payoff',
    modalKey: 'payoff',
    phase: [Phases.DUE_DILIGENCE],
    phaseProgress: Phases.DUE_DILIGENCE,
    loanPurposes: [LoanPurposes.REFI],
    progressButtons: {
      DRAFT: { label: 'Submit', state: 'not submitted' },
      INCOMPLETE: { label: 'Submit', state: 'not submitted' },
      REVIEW: { label: 'View', state: 'in review' },
      APPROVED: { label: 'View', state: 'verified' },
      REQUEST_ERROR: { label: 'View', state: 'error' }
    }
  },
  {
    key: ModuleKeys.PAY_APPRAISAL,
    name: 'Appraisal Payment',
    modalKey: 'pay_appraisal',
    phase: [Phases.APPROVAL],
    phaseProgress: Phases.DUE_DILIGENCE
  },
];

export const findTaskRules = (_key: string) => {
  const { key, phase, name }: any = RulesTasksCards.find(item => item?.key === _key)

  return {
    taskKey: key,
    taskName: name,
    phase: PhasesLabel[phase[0]]
  }
}