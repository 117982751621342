import React, { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import { Client, Provider, cacheExchange, fetchExchange, useQuery } from 'urql';
import { AuthConfig, authExchange } from '@urql/exchange-auth';

import Public from "./scenes/Public/Public";
import Private from './scenes/Private/Private';

function App() {

  const logout = () => {
    localStorage.removeItem('token');
    window.dispatchEvent(new Event("storage"));
  }

  const client = new Client({
    url: 'https://sbb.constlending.com/graphql',
    exchanges: [
      cacheExchange,
      authExchange(async (utils: any) => {
        let token = await localStorage.getItem('token');

        const config: AuthConfig = {
          addAuthToOperation(operation) {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
              'Apollo-Require-Preflight': 'true'
            });
          },
          didAuthError(error, _operation) {
            return error.response?.status === 401
          },
          async refreshAuth() { logout() },
          willAuthError(_operation) {
            return false
          },
        }

        return config;
      }),
      fetchExchange
    ]
  });

  const [isLogged, setIsLogged] = useState<boolean>(false);

  useEffect(() => {
    setIsLogged(!!localStorage.getItem('token'));

    window.addEventListener("storage", () => {
      setIsLogged(!!localStorage.getItem('token'));
    });

    return () => {
      window.removeEventListener("storage", () => { });
    };
  }, [])

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#ffffff',

          colorBgContainer: '#ffffff',
        },
      }}
    >
      <Provider value={client}>
        {isLogged && <Private />}
        {!isLogged && <Public />}
      </Provider>
    </ConfigProvider>
  )
}

export default App;
