import React, { useEffect, useState } from 'react';
import { Task, TaskResponse, TaskState } from 'gql/graphql';
import NewTask from './NewTask';
import { Tooltip } from 'react-tooltip';

interface Props {
  tasks?: Task[] | any[],
}

const useCardNewTask = ({ tasks }: Props) => {
  const [dataTasks, setDataTasks] = useState<Task[]>();

  useEffect(() => {
    if (tasks) {
      const data: Task[] = tasks.filter((item) => !item.key);
      setDataTasks(data);
    }
  }, [tasks]);

  const getTotalApproved = (task: Task) => 
    task?.responses?.filter((item: any) => item.state === TaskState.Approved)?.length;

  const generateCirclesFooter: any = (task: Task) => {
    let response = task?.responses?.find(item => item.key !== 'doc-another');
    
    const addAnotherDocs = task?.responses?.filter(item => item.key === 'doc-another');
    const docsStates = addAnotherDocs?.map(item => item.state) || []; 
    return [(response?.state || 'DRAFT'), ...docsStates];
  };

  const tooltipsTexts: any = {
    "APPROVED": 'Approved',
    "REVIEW": 'In Review',
    "REQUEST_ERROR": 'Rejected',
    "DRAFT": 'Not submmitted',
  }

  const tooltipTexts = (state: string) => tooltipsTexts[state];

  if (!dataTasks)
    return { card: null };

  const cards = dataTasks.map(task => ({
    key: task._id,
    data: task,
    isNewTask: true,
    title: <h4 className="h4">{task.name}</h4>,
    steps: 1,
    stepCurrent: 1,
    type: task.difficulty === 'major_task' ? 'Major task' : 'quick task',
    state: task?.state,
    stateRename: {
      DRAFT: 'NEW TASK'
    },
    actions: task ? [<NewTask key={1} task={task} />] : [],
    text: (
      <p className="body-card">{task.description}</p>
    ),
    text2: (
      <div className='docs-section'>
        <div className='labels'>
          <p className={`body-regular body-regular-bold`}>
            Document status
          </p>
          <p className={`body-card`}>
            {getTotalApproved(task)}/{generateCirclesFooter(task)?.length || 0} submitted
          </p>
        </div>
        <div className={`circles-tags`}>
          {generateCirclesFooter(task)?.map((state: string, index: number) => {
            let _state = state.toUpperCase() || 'DRAFT';

            return (
              <div key={index}>
                <div
                  data-tooltip-id={`circle-state-${_state}`}
                  data-tooltip-html={`<b>${tooltipTexts(_state)}</b>`}
                  className={`circle circle-${_state}`}
                />
                <Tooltip
                  id={`circle-state-${_state}`}
                  className={`circle-state-tooltip circle-state-tooltip-${_state}`}
                />
              </div>
            )
          })}
        </div>
      </div>
    )
  }))

  return {
    cards
  };
};

export default useCardNewTask;