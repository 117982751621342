import { useMutation } from 'urql';
import { LOGIN, RESET_PASSWORD, SEND_EMAIL_RESET_PASSWORD, SEND_EMAIL_RESET_PASSWORD_BY_EMAIL } from './graphql';
import { MutationResetPasswordArgs, MutationSendEmailResetPasswordByEmailArgs, ResetPasswordMutation, SendEmailResetPasswordByEmailMutation } from '../../../gql/graphql';

export const useLogin = (query?: any) => {
    const [{ data, fetching, error }, execute] = useMutation((query || LOGIN));
    return { execute, data, fetching, error };
}

export const useSendEmailResetPasswordByEmail = (query?: any) => {
    const [results, execute] = useMutation<SendEmailResetPasswordByEmailMutation>((query || SEND_EMAIL_RESET_PASSWORD_BY_EMAIL));
    return {
        ...results,
        execute: (data: MutationSendEmailResetPasswordByEmailArgs) => execute(data),
        data: results.data?.SendEmailResetPasswordByEmail,
    };
}

export const useSendEmailResetPassword = (query?: any) => {
    const [{ data, fetching, error }, execute] = useMutation((query || SEND_EMAIL_RESET_PASSWORD));
    return { execute, data, fetching, error };
}

export const useResetPassword = (query?: any) => {
    const [results, execute] = useMutation<ResetPasswordMutation>((query || RESET_PASSWORD));
    return {
        ...results,
        execute: (data: MutationResetPasswordArgs) => execute(data),
        data: results.data?.ResetPassword,
    };
}