import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Step2 from './Step2/Step2';
import {
  Entity,
  Loan,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import { LoanContext } from '../../Dashboard';
import DropdownBlue from 'components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import { useCreateResponseTask } from 'services/task/mutations';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { TaskOpenModalContext } from '../../../Private';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const Payoff = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);
  const loan: Loan | any = useContext(LoanContext);

  const { execute: createResponseTask } = useCreateResponseTask();
  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse | any>();
  const [responseStep2, setResponseStep2] = useState<TaskResponse>();
  const [entity, setEntity] = useState<Entity | any>();

  useEffect(() => {
    if (openContext === 'payoff' || openContext === ModuleKeys.PAYOFF) {
      setModalTaskVisible(true);
      setHandler();
    } else if(!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (isModalTaskVisible)
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [isModalTaskVisible, step])

  useEffect(() => {
    const step1: any = taskResponses?.find((item) => item.step === 1);
    if (step1) setStep(2);
    setResponseStep1(step1);

    const step2: any = taskResponses?.find((item) => item.step === 2);
    setResponseStep2(step2);
    setIsReview(step2?.state === TaskState.Review);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const onChangeStep1 = async (optionKey: "letter" | "contact") => {
    const { createdAt, __typename, task, ...payload } = responseStep1;

    await createResponseTask({
      ...payload,
      metadata: {},
      task: task._id,
      key: optionKey === 'letter' ? 'yes' : 'no',
      label: optionKey === 'letter' ?
        'Yes, I have a payoff letter' : 'No, I have a payoff contact',
    });

    getTaskResponses({ requestPolicy: 'network-only' });
  };

  const { taskName, phase } = findTaskRules(ModuleKeys.PAYOFF);

  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          disabledDots={step === 2}
          responses={taskResponses}
          renderButtonTrigger={(openModal: any) => (
            <Button
              className={`button-open-modal button-open-modal-${task.state}`}
              onClick={() => openModal(true)}
            >
              {task.state === TaskState.Review || task.state === TaskState.RequestError ?
                'Complete task' :
                task.state === TaskState.Draft ?
                  'Begin Task' : 'View Task'
              }
            </Button>
          )}
          header={{
            title: <span>{phase} | {taskName.toUpperCase()}</span>,
            actionLeft: (
              isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
            ),
            actionRight: (
              <Button
                className="button button-transparent"
                onClick={() => onHelpDocs()}
              >
                <QuestionCircleOutlined />
                Help docs
              </Button>
            )
          }}
          animationDirection="onRight"
          stepsTotal={2}
          currentStep={step}
          setStep={setStep}
          footer={
            <div className="payoff-footer text-footer mt-20">
              <p className="placeholder">
                My payoff {responseStep1?.key === 'yes' ? 'is ' : ' '}
                {step === 2 && responseStep2 &&
                  <b
                    className={`bold ${responseStep2.state === TaskState.Review ? 'is-review' : ''}`}
                    style={{ padding: 0 }}
                  >
                    {responseStep1?.key === 'yes' ? 'letter is not submitted' : 'contact is not submitted'}
                  </b>
                }
                {step === 2 && !responseStep2 &&
                  <DropdownBlue
                    label={responseStep1?.key === 'yes' ? 'letter is not submitted' : 'contact is not submitted'}
                    onClickSelect={onChangeStep1}
                    items={responseStep1?.key !== 'yes' ?
                      [{ key: 'letter', label: 'Letter is not submitted' }] :
                      [{ key: 'contact', label: 'Contact is not submitted' }]
                    }
                    className={`dropdown-blue-bold`}
                  />
                }
              </p>
            </div>
          }
        >
          <section className="children payoff">
            {step === 1 && (
              <Step1
                setStep={setStep}
                closeTask={() => setModalTaskVisible(false)}
              />
            )}
            {step === 2 && (
              <Step2
                setStep={setStep}
                closeTask={() => setModalTaskVisible(false)}
              />
            )}
          </section>
        </ModalTask>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default Payoff;
