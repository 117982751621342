import React, { useContext, useEffect, useState } from 'react';
import QuestionBasic from 'components/Organisms/QuestionBasic/QuestionBasic';
import { Button, Spin } from 'antd';
import {
  SubmitContext,
  SubmitContextInterface,
  TaskResponsesContext
} from '../EntityGuarantorCreation';
import { Loan, TaskResponse } from 'gql/graphql';
import DropdownBlue from '../../../../../../components/Atoms/Dropdown/DropdownBlue/DropdownBlue';
import { useStep1 } from './useStep1';

interface Step1Props {
  setStep: Function;
  step2Confirm?: any;
  responsesStep2?: any[];
  setResponseStep1Draft?: any;
  getTaskResponses: Function;
  setHandlerSubmit: any;
}
const Step1 = (props: Step1Props) => {
  const taskResponses: TaskResponse | any = useContext(TaskResponsesContext);
  const onSubmitParent: SubmitContextInterface | any =
    useContext(SubmitContext);

  const {
    entity,
    optionsExistEntities,
    optionsNotEntities,
    ownerships,
    response,
    fetching,
    onSaveStep1,
    entities,
    ownerSelect,
    setOwnerSelect
  } = useStep1(props);

  if (onSubmitParent.active && onSubmitParent.step === 1)
    return (
      <section className="loader">
        <Spin />
      </section>
    );

  let owner = ownerships?.find((item) => item._id === ownerSelect);

  useEffect(() => {
    if (response?.title === "No") {
      props.setResponseStep1Draft(true)
    } else {
      props.setResponseStep1Draft(false)
    }
    if (props.step2Confirm?.step === 2) {
      props.setResponseStep1Draft(false)
    }
  }, [response, props.step2Confirm])

  return (
    <section className="step-1">
      <div className="section-1">
        <h3 className="h3">
          {ownerships && (
            <DropdownBlue
              label={owner ? owner?.name + ' ' + owner?.lastname : 'Someone new'}
              items={
                [
                  ...ownerships
                    // .filter((item) => item._id !== owner?._id)
                    .map((item) => ({
                      key: item._id,
                      label: (
                        <span className={`${item._id === ownerSelect ? "item-selected" : ""}`}>
                          {item.name} {item.lastname}
                        </span>
                      )
                    })),
                  {
                    key: 'someone_new', label: (
                      <span className={`${"someone_new" === ownerSelect ? "item-selected" : ""}`}>
                        Someone new
                      </span>
                    )
                  },
                ]
              }
              onClickSelect={(key: string) => setOwnerSelect(key)}
              className="dropdown-blue-bold"
            />
          )}
          {(!entities || entities?.length === 0) && <>{' '}isn’t a personal guarantor on any entities. Let’s make one now</>}
          {entities && entities?.length > 0 && <>{' '}has ownership on the following entities</>}
        </h3>
        <div className="mt-60">
          <QuestionBasic
            type="dropdown"
            metadata={{ entity, taskResponses }}
            question={
              <h3 className="h2">
                {(!entities || entities.length === 0) &&
                  'Do you already have a legal entity that you want to use for this loan?'}
                {entities && entities?.length > 0 && 'Which entity will be the borrower on this loan? '}
              </h3>
            }
            optionSelected={response}
            options={
              entities && entities?.length > 0
                ? optionsExistEntities
                : optionsNotEntities
            }
          />
        </div>
        {/*response?.id === 5 && (
          <div className="card__generic mt-20">
            <h4 className="h4 h4-w5">
              {entities && entities?.length > 0 ? 'Another entity I haven’t formed yet' : "No"}
            </h4>
            <p className="h4 h4-w4">
              {(!entities || entities?.length === 0) &&
                `We only do investment-purpose loans. That means you’ll need a
              legal entity to borrow with us. It’s ok if you don’t have one yet,
              but we do need to run a background and credit check on anyone who
              is going to be a personal guarantor on a loan.`}
              {entities &&
                entities?.length > 0 &&
                'Usually you’ll need a legal entity to borrow with us. We may be able to approve the loan without one, but we do need to run a background and credit check on anyone who is going to be a personal guarantor on the loan.'}
            </p>
            <Button
              className="button-purple body-regular"
              loading={fetching}
              onClick={() =>
                onSaveStep1(
                  '100%-entity',
                  'I will own 100% of the entity',
                  taskResponses,
                  entity
                )
              }
            >
              I will own 100% of the entity
            </Button>
            <Button
              className="button-purple body-regular"
              loading={fetching}
              onClick={() =>
                onSaveStep1(
                  'multiple-owners',
                  'My entity will have multiple owners',
                  taskResponses,
                  entity
                )
              }
            >
              My entity will have multiple owners
            </Button>
          </div>
        )*/}
      </div>
    </section>
  );
};
export default Step1;
