import React, { createContext, useContext, useEffect, useState } from 'react';
import ModalTask from 'components/Organisms/Modals/ModalTask/ModalTask';
import iconCortPng from 'assets/images/home.png';
import Step1 from './Step1/Step1';
import { Button } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import {
  Entity,
  Task,
  TaskResponse,
  TaskState
} from '../../../../../gql/graphql';
import { useGetTaskResponsesByTask } from '../../../../../services/task/querys';
import StateTag from 'components/Atoms/Tags/StateTag/StateTag';
import { ModuleKeys } from 'services/task/modules-keys.constanst';
import { findTaskRules } from '../../RulesTaskCards.constanst';
import { TaskOpenModalContext } from '../../../Private';

export const TaskContext = createContext<Task | {}>({});
export const EntityContext = createContext<Entity | {}>({});

interface Props {
  task: Task;
}

const Purchase = ({ task }: Props) => {
  const { value: openContext, setHandler }: any = useContext(TaskOpenModalContext);

  const { data: taskResponses, reexecute: getTaskResponses } = useGetTaskResponsesByTask({
    variables: { findResponsesByTask: { taskId: task._id } }
  });

  const [isModalTaskVisible, setModalTaskVisible] = useState(false);
  const [isReview, setIsReview] = useState(false);
  const [step, setStep] = useState(1);
  const [responseStep1, setResponseStep1] = useState<TaskResponse>();
  const [entity, setEntity] = useState<Entity | any>();

  useEffect(() => {
    if (openContext === 'purchase') {
      setModalTaskVisible(true);
      setHandler();
    } else if(!!openContext)
      setModalTaskVisible(false);
  }, [openContext]);

  useEffect(() => {
    if (isModalTaskVisible)
      getTaskResponses({ requestPolicy: 'network-only' });
  }, [isModalTaskVisible])

  useEffect(() => {
    const step1: any = taskResponses?.find((item) => item.step === 1);
    setResponseStep1(step1);
    setIsReview(task.state === TaskState.Review);
  }, [taskResponses]);

  const onHelpDocs = () => {
    console.log('onHelpDocs');
  };

  const { taskName, phase } = findTaskRules(ModuleKeys.PURCHASE);

  return (
    <TaskContext.Provider value={task}>
      <EntityContext.Provider value={entity}>
        <ModalTask
          visible={isModalTaskVisible}
          handlerSetVisibile={setModalTaskVisible}
          isConfirmModalClose={task.state !== TaskState.Approved && !isReview}
          responses={taskResponses}
          renderButtonTrigger={(openModal: any) => (
            <Button
              className={`button-open-modal button-open-modal-${task.state}`}
              onClick={() => openModal(true)}
            >
              {task.state === TaskState.Review || task.state === TaskState.RequestError ?
                'Complete task' :
                task.state === TaskState.Draft ?
                  'Begin Task' : 'View Task'
              }
            </Button>
          )}
          header={{
            title: <span>{phase} | {taskName.toUpperCase()}</span>,
            actionLeft: (
              isReview ? <StateTag state={TaskState.Review} size="large" /> : <img src={iconCortPng} alt="icon" className="paperPng" />
            ),
            actionRight: (
              <Button
                className="button button-transparent"
                onClick={() => onHelpDocs()}
              >
                <QuestionCircleOutlined />
                Help docs
              </Button>
            )
          }}
          animationDirection="onRight"
          stepsTotal={1}
          currentStep={step}
          setStep={setStep}
          footer={null}
        >
          <section className="children AchPaymentForm">
            {step === 1 && (
              <Step1
                setStep={setStep}
                closeTask={() => setModalTaskVisible(false)}
              />
            )}
          </section>
        </ModalTask>
      </EntityContext.Provider>
    </TaskContext.Provider>
  );
};
export default Purchase;