import React, { useContext, useEffect, useState } from 'react';
import RightScenes from '../components/Menu/BarDashboard/RightScenes/RightScenes';
import { EntitiesViewContext } from '../Private';
import searchIcon from 'assets/images/search.svg'
import { Button, Dropdown } from 'antd';
import { useGetEntitiesListByOwner } from '../../../services/entity/querys';
import { Entity, Loan, TaskState } from '../../../gql/graphql';
import { Tooltip } from 'react-tooltip';
import { TOOLTIP_STATES } from '../../../@common/utils/constants/tooltip-states.constant';
import { PhasesLabel } from '../Dashboard/LoanPhases.constanst';

const Entities = () => {

  const [searchTerm, setSearchTerm] = useState<string | undefined>(undefined);

  const { data: entities } = useGetEntitiesListByOwner({
    variables: { search: searchTerm },
  });

  const { visible, setHandlerVisible }: any = useContext(EntitiesViewContext);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  }

  const countGuarantors = (entity: Entity, isIncompletes?: boolean) => {
    return entity.ownerships?.filter((item: any) =>
      item.stakes[entity._id] && (!isIncompletes || item.state === TaskState.Approved)
    )
      .filter((item: any) => item.stakes[entity._id] >= 20)?.length;
  }

  const getGuarantorsName = (entity: Entity) =>
    entity.ownerships?.filter((item: any) => item.stakes[entity._id] >= 20)
      .map((item: any, index: number, array: any) => {
        if (index === array.length - 1 && array.length > 1)
          return `${array.length >= 3 ? ',' : ''}and ${item.name} ${item.lastname}`;

        return item.name + ' ' + item.lastname;
      })
      .filter((item: any) => !!item)
      .join(', ')
      .replace(/,([^,]*)$/, '$1');

  const countDocs = (entity: any, isIncompletes?: boolean) =>
    entity.docs?.filter((item: any) => (!isIncompletes || item.state === TaskState.Approved))?.length;

  const getStateLoan = (progress: any) => {
    if (!progress) return 'APPROVAL';
    
    const phaseStarted = progress.findLast((item: any) => item.state !== 'not-started')
    return phaseStarted ? PhasesLabel[phaseStarted.key] : 'APPROVAL';
  }

  return (
    <RightScenes
      visible={visible}
      handlerSetVisibile={setHandlerVisible}
    >
      <div className='view-entities'>
        <div className='view-entities-header'>
          <h1 className='title'>Entities</h1>
          <div className='search-box'>
            <img src={searchIcon} alt="search" />
            <input
              type="text"
              placeholder="Search by entity or guarantor name"
              value={searchTerm}
              onChange={handleSearch}
            />
          </div>
        </div>

        <div className='view-entities-content'>
          {entities?.map((entity: any) => (
            <div key={entity._id} className='card-entity'>
              <div className='card-entity-header'>
                <h3>{entity.name}</h3>
                <div className='card-entity-header-description'>
                  <p>{countGuarantors(entity as any)} Guarantors | {entity.loans?.length} Loans</p>
                  <Dropdown
                    className="DrodownBlue see-loan"
                    menu={{
                      items: entity.loans.map((loan: Loan, index: number) => ({
                        key: index,
                        label: (
                          <div className='dropdown-item-loan'>
                            <div className='dropdown-item-loan-state'>
                              <span className='tag-state-blue'>
                                {getStateLoan(loan.progress)}
                              </span>
                            </div>
                            <span className='dropdown-item-loan-name'>
                              <b>{loan.name}</b> <span>| {loan.type}</span>
                            </span>
                            <span>View loan &#8594;</span>
                          </div>
                        ),
                      })), onClick: () => { }
                    }}
                    placement="bottom"
                    overlayClassName="DrodownBlue-blue"
                  >
                    <Button type="link" className="button button-link-blue">
                      See loans
                    </Button>
                  </Dropdown>
                </div>
              </div>
              <div className='card-entity-content'>
                <div className='card-entity-content-guarantors'>
                  <div className='card-entity-content-resume hover-edit'>
                    <label>Guarantors</label>
                    <span>{countGuarantors(entity as any, true)}/{countGuarantors(entity as any)} complete profiles</span>
                    <div className='box-shadow'>
                      <span>View and edit guarantor profiles</span>
                    </div>
                  </div>
                  <div className='guarantors-names'>
                    {getGuarantorsName(entity as any)}
                  </div>
                </div>
                <div className='card-entity-content-documents'>
                  <div className='card-entity-content-resume'>
                    <label>Documents</label>
                    <span>{countDocs(entity, true)}/4 unsubmitted docs</span>
                  </div>
                  <div className='doc-states'>
                    <div className={`circles-tags`}>
                      {entity.docs.map((doc: any, index: number) => (
                        <div key={index}>
                          <div
                            data-tooltip-id={`circle-state-${index}`}
                            data-tooltip-html={`<b>${TOOLTIP_STATES[doc.state]}</b>`}
                            className={`circle circle-${doc.state}`}
                          />
                          <Tooltip
                            id={`circle-state-${index}`}
                            className={`circle-state-tooltip circle-state-tooltip-${doc.state}`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <Button className="button button-link-border card-entity-button">
                Use in new loan
              </Button>
            </div>
          ))}

        </div>
      </div>
    </RightScenes>
  );
};
export default Entities;
