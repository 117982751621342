import React from "react";
import PropTypes from 'prop-types';

import Header from "scenes/Public/components/Header/Header";
import logoPng from "assets/images/Const Lending.png";

const Layout = ({ children }: any) => {
  return (
    <section className="Layout">
      <div className="BarLogin">
        <img src={logoPng} alt="logo" className="logo" />
      </div>
      <div className="content">
        <Header />
        {children}
      </div>
    </section>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
