import { gql } from "urql";

export const LOGIN: any = gql(`
    mutation login($loginUserInput: LoginUserInput!) {
        login(loginUserInput: $loginUserInput) {
            user {
                _id
                name
            }
            authToken
        }
    }
`)

export const SEND_EMAIL_RESET_PASSWORD: any = gql(`
    mutation SendEmailResetPassword {
        SendEmailResetPassword 
    }
`)

export const SEND_EMAIL_RESET_PASSWORD_BY_EMAIL: any = gql(`
    mutation SendEmailResetPasswordByEmail($email: String!) {
        SendEmailResetPasswordByEmail(email: $email) 
    }
`)

export const RESET_PASSWORD: any = gql(`
    mutation ResetPassword($newPassword: String!, $token: String!) {
        ResetPassword(newPassword: $newPassword, token: $token)
    }
`)