import React, { createContext, useContext, useEffect, useState } from 'react';
import moment from 'moment';

import CardLoanTasks from 'components/Atoms/Cards/CardLoanTasks/CardLoanTasks';
import HeaderHome from 'scenes/Private/components/Menu/HeaderDashboard/HeaderDashboard';
import BarDashboard from 'scenes/Private/components/Menu/BarDashboard/BarDashboard';
import CardProfile from 'scenes/Private/components/CardProfile/CardProfile';
import ButtonTabsFilters from 'components/Atoms/ButtonTabsFilters/ButtonTabsFilters';
import ProgressSteps from 'components/Atoms/Progress/ProgressSteps/ProgressSteps';

import { Loan, Task } from 'gql/graphql';
import avatarSvg from 'assets/images/avatar.png';
import useDashboard from './useDashboard';
import { TaskOpenModalContext } from '../Private';
import AddAdditionalDocument from './components/AddAdditionalDocument/AddAdditionalDocument';
import LoanProgress from 'components/Atoms/Progress/LoanProgress/LoanProgress';

export const LoanContext = createContext<Loan | {}>({});
export const TasksContext = createContext<Task[] | []>([]);
export const ProgressContext = createContext<any>({});
export const CreateTaskResponseContext = createContext<{ value: string; setValue: any } | {}>({});

const Dashboard = () => {
  const { setHandler: setTaskOpenModal }: any = useContext(TaskOpenModalContext);
  
  const [progressData, setProgressData] = useState();

  const {
    loans,
    loanSelect,
    tasks,

    isCreateTaskResponse,
    setCreateTaskResponse,

    phaseNow,
    phaseSelect,
    setPhaseSelect,

    filter,
    setFilter,

    rulesTasksCards,
    tasksFilter,
    taskCards,
    phaseSection,
  } = useDashboard();

  /*
  {
      title: <h4 className="h4">Add additional document</h4>,
      steps: 1,
      text: (
        <p className="body-card">
          My entity{' '}
          <span className="body-card-bold-line">is/isn’t formed yet</span>, but
          it will have <span className="body-card-bold-line"># owners</span> who
          own more than 20%. The owners/loan guarantors are ...
        </p>
      ),
      type: 'Major task',
      state: TaskState.Draft,
      actions: [
        <AddAdditionalDocument
          key={1}
          classButton="withBorder"
          title={<h2 className="h2">Add another document</h2>}
          subTitle={
            <p className="h4 h4-w4 mt-5">
              Think we missed something that’s relevant to your loan? Add it
              here
            </p>
          }
        />
      ]
    }
  */

  if (!loans) return null;

  let firstPhaseSection, othersPhasesSection;

  if (phaseNow) {
    firstPhaseSection = phaseSection.find((item: any) => item.key === phaseNow?.key)
    othersPhasesSection = phaseSection.filter((item: any) => item.key !== phaseNow?.key)
  }

  return (
    <LoanContext.Provider value={loanSelect as Loan}>
      <TasksContext.Provider value={tasks as Task[]}>
        <ProgressContext.Provider value={progressData}>
          <CreateTaskResponseContext.Provider value={{ value: isCreateTaskResponse, setValue: setCreateTaskResponse }}>
            <div className="Dashboard">
              <HeaderHome />
              <div className="Dashboard--container">
                <BarDashboard />
                <div className="Dashboard__column">
                  <div className="Dashboard__head">
                    <div className="content">
                      {loanSelect?.onboarding?.estimateClosingDate &&
                        <p className="body-regular">
                          <span className="body-regular-bold">{phaseNow?.label} |</span>{' '}
                          Est. Closing:{' '}
                          <span className="body-regular-bold">
                            {moment(loanSelect?.onboarding?.estimateClosingDate).format('MM/DD/YY')}
                          </span>
                        </p>
                      }
                      <h1 className="h1">{loanSelect?.name}</h1>
                      <div className="content-progress">
                        <span className="body-small">Loan stage</span>
                        <LoanProgress
                          loan={loanSelect}
                          phaseSelect={phaseSelect}
                          rulesTasksCards={rulesTasksCards}
                          handlerSetPhaseSelect={setPhaseSelect}
                          handlerSetTaskOpenModal={setTaskOpenModal}
                          handlerSetFilter={setFilter}
                          handlerSetProgressData={setProgressData}
                        />
                      </div>
                    </div>
                    <CardProfile
                      avatar={avatarSvg}
                      name="Andrew Schuclell"
                      email="andrew@constlending.com"
                      phone="213-232-3248"
                    />
                  </div>
                  <div className="Dashboard__label">
                    <p className="body-small">
                      <span className="body-small-w4">
                        {loanSelect?.type}
                        {loanSelect?.purpose ? (' | ' + loanSelect?.purpose) : ''}
                        {loanSelect?.ltv ? (' | ' + loanSelect?.ltv + '% LTV') : ''}
                      </span>{' '}
                      <span className="body-small-w6">
                        See Loan Details and Docs
                      </span>
                    </p>
                  </div>
                  <div className={`first-section first-section-${firstPhaseSection.state !== 'DO' && 'DONE'}`}>
                    <div className="Dashboard__head Dashboard__head-tabs">
                      <div className="box-title">
                        <p className="red-title">{firstPhaseSection.state === 'DO' ? 'DO NOW' : 'DONE'}</p>
                        <h1 className="h2">
                          {firstPhaseSection.label + ' Tasks'}
                        </h1>
                      </div>
                      <ButtonTabsFilters phaseSelect={phaseSelect} tasks={tasksFilter} filter={filter} handlerChange={setFilter} />
                    </div>
                    <div className="content-link">
                      <AddAdditionalDocument />
                    </div>
                    <div className="content-flex-wrap">
                      {loans && firstPhaseSection?.tasks?.map((task: any, index: number) => (
                        <CardLoanTasks key={index} taskKey={task.key} {...task} />
                      ))}
                    </div>
                  </div>

                  {othersPhasesSection.map((item: any) => (
                    item.tasks.length > 0 && (
                      <div key={item.key} className={`second-section second-section-${item.state}`}>
                        <div className="Dashboard__head Dashboard__head-tabs">
                          <div className="box-title">
                            <p className="red-title">
                              {item.state === 'DO' ? 'DO NOW' : item.state === 'DO_LATER' ? 'DO LATER' : 'DONE'}
                            </p>
                            <h1 className="h2">
                              {item.label + ' Tasks'}
                            </h1>
                          </div>
                        </div>
                        <div className="content-flex-wrap">
                          {loans && item?.tasks?.map((task: any, index: number) => (
                            <CardLoanTasks key={index} taskKey={task.key} {...task} />
                          ))}
                        </div>
                      </div>
                    )
                  ))}
                </div>
              </div>
            </div>
          </CreateTaskResponseContext.Provider>
        </ProgressContext.Provider>
      </TasksContext.Provider>
    </LoanContext.Provider>
  );
};
export default Dashboard;
